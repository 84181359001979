import React, { useState } from 'react'
import '../css/Rules.css'

const Rules = ({ contract }) => {
    const [rule, setRule] = useState("規則")
    if (contract === null) return;
    console.log(contract)

    const getRule = async () => {
        console.log("HI")
        if (rule !== "規則") return
        let tempRule = await contract.viewRule();
        console.log(tempRule)
        setRule(tempRule)
    }

    getRule()

    return (
        <div className='rulePage'>
            <div className='ruleBox'>
                <h4>{rule}</h4>
            </div>
        </div>
    )
}

export default Rules
