import React, { useState } from 'react'
import HistoryContainer from '../history/HistoryContainer'


const History = ({ contract }) => {
    const [historyData, setHistoryData] = useState(null);

    if (contract === null) return;

    const getData = async () => {
        if (historyData !== null) return;
        let data = await contract.viewHistory();
        setHistoryData(data)
    }

    getData();

    if (historyData === null) return;

    return (
        <div className='historyPage'>
            <div className='historyContent'>
                <HistoryContainer data={historyData} contract={contract} />
            </div>
        </div>
    )
}

export default History