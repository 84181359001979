const ethers = require("ethers");
const contractABI = require('./usdtabi.json');
const pairABI = require('./pairabi.json');
const React = require('react');
const { useState } = require('react');
const Web3 = require('web3');

// let BSCMainnetProvider = 'https://bsc-dataseed4.ninicoin.io/';

let BSCMainnetProvider = 'https://mainnet-rpc.hashbit.org';

// let BSCMainnetProvider = 'https://bsc-dataseed1.binance.org';
const web3 = new Web3(new Web3.providers.HttpProvider(BSCMainnetProvider));

let currentProvider = new web3.providers.HttpProvider(BSCMainnetProvider);
const provider = new ethers.providers.Web3Provider(currentProvider);


/* 
=================================================
=================================================
 
                Parameters 

=================================================
=================================================
*/

const CONTRACT_ADDRESS = '0x3c80068E8EaD7ee7f03fEbED9d54675fCdD29510';
const pairAddress = "0xEdda30d6b27e097c7cacB9ba56b7774A27e4Aadd"
const routerAddress = "0x3BAb21D55Bc77995943c61588decCAa9c56CF634"
const checkContract = new ethers.Contract(CONTRACT_ADDRESS, contractABI, provider);

//Filtering the buying event...
const filter = checkContract.filters.Transfer(pairAddress, null, null);

function CheckTransfer({ contract, winner }) {

    const [isSuccess, setIsSuccess] = useState(false)

    const [tempLength, setTempLength] = useState(0)

    const [historyData, setHistoryData] = useState(null);
    const [startBlock, setStartBlock] = useState(0);
    const [roundData, setRoundData] = useState(null);
    const [roundTime, setRoundTime] = useState(0);

    if (contract === null) return;

    const getHistoryData = async () => {
        if (historyData !== null) return;
        let data = await contract.viewHistory();
        setHistoryData(data)
    }

    const getRoundData = async () => {
        if (roundData !== null) return;
        let tempRoundData = await contract.roundID()
        setRoundData(tempRoundData);
    }
    const getStartBlock = async () => {
        if (historyData === null) return;
        if (historyData[roundData - 1] === undefined) return;
        if (startBlock !== 0) return;
        let tempStartBlock = historyData[roundData - 1][1];
        console.log("Successfully set : " + tempStartBlock)
        setStartBlock(tempStartBlock);
    }
    const getRoundTime = async () => {
        if (roundTime !== 0) return;
        let tempRoundTime = await contract.timeForRound()
        console.log("Successfully set : " + tempRoundTime)
        setRoundTime(tempRoundTime);
    }

    const getData = async () => {
        await getHistoryData()
        await getStartBlock()
        await getRoundData()
        await getRoundTime()
    }

    getData()

    if (historyData === null) return;
    if (roundData === null) return;
    if (startBlock === 0) return;
    if (roundTime === 0) return;

    const getLength = async (from, to) => {
        const results = await checkContract.queryFilter(filter, from, to);
        return results.length;
    }

    const searchInAdvance = async () => {
        /*
            搜尋 上次開始時間 到現在為止 有沒有符合條件的
        */
        if (isSuccess === true) return;

        let startBlockNumber = Number(startBlock)
        const blockNumber = await web3.eth.getBlockNumber()
        const results = await checkContract.queryFilter(filter, startBlockNumber, blockNumber);

        let length = await getLength(startBlockNumber, blockNumber)
        if (length === tempLength) return;
        setTempLength(length);
        // console.log("Length : " + length)

        if (length === 0) {
            console.log("No one has bought since last start")
            return;
        }

        let resultBlockNumber = results[length - 1].blockNumber;

        if ((blockNumber - resultBlockNumber) >= roundTime) {
            //肯定有成功的
            console.log("Last interval = " + (blockNumber - resultBlockNumber))
            console.log("SUCCESS")
            //找看看是誰成功
            if (length === 1) {
                winner(results[0].args.to)
                return;
            }

            if (length > 1) {
                let resultlooping = 0

                console.log(`${length} has bought since last start`)

                for (resultlooping = 0; resultlooping < length; resultlooping++) {
                    //Calculate time interval
                    let tempTime
                    if (results[resultlooping].args.to === CONTRACT_ADDRESS) { }
                    else {
                        if (tempTime === 0) tempTime = results[resultlooping].blockNumber
                        if (tempTime !== 0) {
                            let timeInterval = 0;
                            if (tempTime === results[resultlooping].blockNumber) { }
                            else {
                                timeInterval = results[resultlooping].blockNumber - tempTime;
                                if (timeInterval >= roundTime) {
                                    //Answer!!
                                    winner(results[resultlooping].args.to)
                                    return;
                                }
                                if (resultlooping === length - 1) {
                                    winner(results[resultlooping].args.to)
                                    return;
                                }
                            }
                        }
                    }
                }
            }
            return;
        }
    }

    searchInAdvance()
    return (
        <div>
        </div>
    )
}

export default CheckTransfer;