import React, { useState } from 'react';
import { ethers } from 'ethers'
import './history.css'
import { Link } from 'react-router-dom';

const HistoryCard = ({ data, contract }) => {
    let linkex = "https://bscscan.com/address/${}"
    let GuessID = data[3].toNumber()
    let time = data[0].toNumber()
    let HowMany = (data[4]/Math.pow(10,18)).toFixed(2)
    console.log(HowMany);
    const noWinner = "No Winner Yet!!"
    const Address0x = "0x0000000000000000000000000000000000000000"
    const [winner, setWinner] = useState(noWinner)
    const [realtime, setRealtime] = useState(null);
    const [group, setGroup] =useState("未")
    const [link, setLink] = useState(null)

    const getWinner = async () => {
        if (winner !== noWinner) return;
        if (link !== null) return;
        let tempWinner = await contract.roundWinner(GuessID);
        setLink(`https://bscscan.com/address/${tempWinner}`)
        if (tempWinner === Address0x) return;

        setWinner(`${tempWinner.slice(0, 4)}...${tempWinner.slice(-4)}`)
    }

    const readData = async () => {
        if (realtime !== null) return;
        let date = new Date(time * 1000)
        let formatdate = date.toLocaleString();
        setRealtime(formatdate);
    }

    readData();
    getWinner()

    return (
        <div>
            <div className='historyCardBox'>
                <div className='historyCardRowLeft'>
                    <h5>{GuessID}</h5>
                </div>

                <div className='historyCardRow'>
                    <div className='historyCardUpperRow'>
                        <div className='historyCardUpperRowAddress'>
                            <a href={link} id="historyLinktoAccount"><h5>Winner : {winner}</h5></a>
                        </div>
                    </div>
                    <div className='historyCardLowerRow'>
                        <div className='historyCardLowerRowReward'>
                            <h5>Reward : {HowMany}</h5>
                        </div>
                    </div>
                </div>

                <div className='historyCardRowRight'>
                    <h5>{group}</h5>
                </div>
            </div>
        </div>
    );
};

export default HistoryCard;