const ethers = require("ethers");
const contractABI = require('./usdtabi.json');
const pairABI = require('./pairabi.json');
const React = require('react');
const { useEffect } = require('react')
const { useState } = require('react');
const Web3 = require('web3');

// let BSCMainnetProvider = 'https://bsc-dataseed4.ninicoin.io/';

let BSCMainnetProvider = 'https://mainnet-rpc.hashbit.org';

// let BSCMainnetProvider = 'https://bsc-dataseed1.binance.org';
const web3 = new Web3(new Web3.providers.HttpProvider(BSCMainnetProvider));

let currentProvider = new web3.providers.HttpProvider(BSCMainnetProvider);
const provider = new ethers.providers.Web3Provider(currentProvider);

/* 
=================================================
=================================================
 
                Parameters 

=================================================
=================================================
*/

const CONTRACT_ADDRESS = '0x3c80068E8EaD7ee7f03fEbED9d54675fCdD29510';
const pairAddress = "0xEdda30d6b27e097c7cacB9ba56b7774A27e4Aadd"
const routerAddress = "0x3BAb21D55Bc77995943c61588decCAa9c56CF634"
const contract = new ethers.Contract(CONTRACT_ADDRESS, contractABI, provider);
//Filtering the buying event...
const filter = contract.filters.Transfer(pairAddress, null, null);

function CheckTransfer(props) {
    let startBlock = props.startBlock;
    let roundTime = props.roundTime;

    const [buyer, setBuyer] = useState(null);
    const [isSuccess, setIsSuccess] = useState(false)
    const [blockNow, setBlockNow] = useState(0)
    const [isLoading, setIsLoading] = useState(true)

    const [tempBuyer, setTempBuyer] = useState("No one yet")
    const [tempBuyTime, setTempBuyTime] = useState(0)
    const [tempLength, setTempLength] = useState(0)

    const getBlockTime = async () => {
        let tempBlock = await web3.eth.getBlockNumber()
        if (blockNow !== tempBlock)
            setBlockNow(tempBlock)
        setTimeout(() => getBlockTime(), 3000)
    }

    getBlockTime()

    const getLength = async (from, to) => {
        const results = await contract.queryFilter(filter, from, to);
        return results.length;
    }

    const searchInAdvance = async () => {
        /*
            搜尋 上次開始時間 到現在為止 有沒有符合條件的
        */
        if (isSuccess === true) return;

        let startBlockNumber = Number(startBlock)
        const blockNumber = await web3.eth.getBlockNumber()
        console.log(blockNumber)
        const results = await contract.queryFilter(filter, startBlockNumber, blockNumber);
        // let result = await web3.eth.getTransaction(results[0].transactionHash)
        // console.log(result)

        let length = await getLength(startBlockNumber, blockNumber)
        console.log("Length : " + length)

        if (length === 0) {
            if (isLoading === false) return;
            console.log("No one has bought since last start")
            setIsLoading(false)
            return;
        }
        if (length === tempLength) return;
        setTempLength(length);

        let resultBlockNumber = results[length - 1].blockNumber;

        if ((blockNumber - resultBlockNumber) >= roundTime) {
            //肯定有成功的
            console.log("Last interval = " + (blockNumber - resultBlockNumber))
            console.log("SUCCESS")
            //找看看是誰成功
            if (length === 1) {
                setBuyer(results[0].args.to)
                setIsSuccess(true)

                setIsLoading(false)
                return;
            }

            if (length > 1) {
                let resultlooping = 0

                console.log(`${length} has bought since last start`)

                for (resultlooping = 0; resultlooping < length; resultlooping++) {
                    //Calculate time interval
                    let tempTime
                    if (results[resultlooping].args.to === CONTRACT_ADDRESS) { }
                    else {
                        if (tempTime === 0) tempTime = results[resultlooping].blockNumber
                        if (tempTime !== 0) {
                            let timeInterval = 0;
                            if (tempTime === results[resultlooping].blockNumber) { }
                            else {
                                timeInterval = results[resultlooping].blockNumber - tempTime;
                                if (timeInterval >= roundTime) {
                                    //Answer!!
                                    setBuyer(results[resultlooping].args.to)
                                    setIsSuccess(true)
                                    setIsLoading(false)
                                    return;
                                }
                                if (resultlooping === length - 1) {
                                    setBuyer(results[resultlooping].args.to)
                                    setIsSuccess(true)
                                    setIsLoading(false)
                                    return;
                                }
                            }
                        }
                    }
                }
            }
            return;
        }


        if (length === 1) {
            console.log("Only one has bought since last start")
            console.log("Time interval = " + (blockNumber - results[0].blockNumber))
            setTempBuyTime(results[0].blockNumber)
            setTempBuyer(results[0].args.to)
            setIsLoading(false)
            return;
        }

        if (length > 1) {
            console.log(`${length} has bought since last start`)
            setIsLoading(false)
            if (results[length - 1].args.to === CONTRACT_ADDRESS) {
                // The previous one is the buyer
                let buyer = results[length - 2].args.to
                if (buyer !== tempBuyer) {
                    console.log("1")
                    setTempBuyer(buyer)
                    setTempBuyTime(results[length - 2].blockNumber)
                }
            }
            if (results[length - 1].args.to !== CONTRACT_ADDRESS) {
                let buyer = results[length - 1].args.to
                if (buyer !== tempBuyer) {
                    console.log(buyer)
                    console.log("2")
                    setTempBuyer(buyer)
                    setTempBuyTime(results[length - 1].blockNumber)
                }
            }
            return;
        }
    }

    searchInAdvance()

    const [timeLeft, setTimeLeft] = useState(roundTime * 3)

    useEffect(() => {
        if (blockNow === 0) return
        if (tempBuyTime === 0) return;
        let interval;

        if (timeLeft > 0) {
            interval = setInterval(() => {
                setTimeLeft(roundTime * 3 - (blockNow - tempBuyTime) * 3);
            }, 3000);
        }

        if (roundTime * 3 - (blockNow - tempBuyTime) * 3 <= 0) {
            setIsLoading(false)
            setIsSuccess(true)
            setBuyer(tempBuyer)
            makeAlert()
        }
        
        return () => clearInterval(interval);
    }, [blockNow - tempBuyTime]);

    const [hasAlert, setHasAlert]= useState(false)
    const makeAlert = () => {
        if (hasAlert) return;
        alert("The time is end!!")
        setHasAlert(true)
    }

    

    const hours = Math.floor(timeLeft / 3600);
    const minutes = Math.floor((timeLeft % 3600) / 60);
    const seconds = timeLeft % 60;

    return (
        <div>
            {
                !isSuccess && isLoading &&
                <div>
                    Loading...
                </div>
            }
            {
                !isSuccess && tempBuyer === "No one yet" && !isLoading &&
                <div>
                    The last buyer is : {tempBuyer}
                </div>
            }
            {
                !isSuccess && tempBuyer !== "No one yet" && !isLoading &&
                <div>
                    <div>
                        The last buyer is : {tempBuyer.slice(0, 4)}...{tempBuyer.slice(-4)}
                    </div>
                </div>
            }
            {
                !isSuccess && tempBuyTime !== 0 &&
                <div>
                    <br />
                    {hours} : {minutes} : {seconds}
                </div>
            }
            {
                isSuccess && buyer !==null &&
                <div>
                    The Winner is : {buyer.slice(0, 4)}...{buyer.slice(-4)}
                </div>
            }
            {
                isSuccess &&
                <div>
                    <br />
                    請靜待 發獎後開啟下一輪活動
                </div>
            }
        </div>
    )
}

export default CheckTransfer;





// const CONTRACT_ADDRESS = '0xf789c161683e11dbac8640e5cf922827fe002023';
// const pairAddress = "0xb396fc9747cd6546b9e4dc43d4ee701c321e19a6"

// const CONTRACT_ADDRESS = '0xf77bc3c3988C0B7D1599b736c1a66b0c41214201';
// const pairAddress = "0x216877d8ce8c1071e5be8f42ae85d89d064b6e1f"

// const routerAddress = "0x10ED43C718714eb63d5aA57B78B54704E256024E"