import './App.css';
import React, { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import Navbar from './components/Navbar';
import WalletConnect from './components/WalletConnect';
import contractABI from './abi/contractabi.json'
import ControlPanel from './Pages/ControlPanel';


import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Pages/Home';
import Products from './Pages/Products';
import History from './Pages/History';
import Rules from './Pages/Rules';

const ContractAddress = "0x6759d84831CecBb58281E3236bE7934677913412";   //CountDownCA

// const ControllerAddress = "0x135B2cB28B6C6ab1D71571bbe7b45A614A3662d1";
const ControllerAddress = "0x0D971B7B7520f1FCE9b90665CA59952ea2c52b04";
// 初加载刷新
function App() {
  /*
    ====================================
    ====================================
      Wallet Connection Start
    ====================================
    ====================================
  */

  const [provider, setProvider] = useState(null)
  const [signer, setSigner] = useState(null)
  const [defaultAccount, setDefaultAccount] = useState(null)
  const [contract, setContract] = useState(null)
  const [data, setData] = useState(null)
  const [round, setRound] = useState(0)
  const [value, setValue] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const handleAccountChange = async (newValue) => {
    setDefaultAccount(newValue);
  };

  const updateEthers = async () => {
    let tempProvider = new ethers.providers.Web3Provider(window.ethereum);
    setProvider(tempProvider);

    let tempSigner = tempProvider.getSigner();
    setSigner(tempSigner);

    const message = "本签名仅供验证地址使用"
    let signature = await tempSigner.signMessage(message)

    let tempContract = new ethers.Contract(ContractAddress, contractABI, tempSigner)
    setContract(tempContract);
  }

  useEffect(() => {
    if (defaultAccount === null) return;

    setTimeout(async function () {
      await updateEthers()
    }, 1000)
  }, [defaultAccount])

  /*
    ====================================
    ====================================
      Wallet Connection End
    ====================================
    ====================================
  */

  /*
    ====================================
    ====================================
      Contract Function Start
    ====================================
    ====================================
  */

  // const useSomeFunction = async () => {
  //   let result = await contract.isTheRoundEnded();
  //   console.log(result)
  // }

  /*
    ====================================
    ====================================
      Contract Function End
    ====================================
    ====================================
  */
  console.log("In app.js " + contract)

  return (

    <div className="App" id="bg">

      <WalletConnect
        defaultAccountChange={handleAccountChange}
      />
      <Router>
        <Navbar />
        <Routes>
          <Route path='/' exact element={<Home contract={contract} />} />
          <Route path='/history' element={<History contract={contract} />} />
          <Route path='/products' element={<Products />} />
          <Route path='/rules' element={<Rules contract={contract}/>} />
          <Route path='/controlpanel' element=
            {<ControlPanel
              contract={contract}
              account={defaultAccount}
              signer={signer}
            />} />
        </Routes>
      </Router>
      {defaultAccount === null &&
        <div className='default'>
          <div className="defaultWrapper">
            Please Connect Your Wallet to Continue
          </div>
        </div>
      }
    </div>

  );
}

export default App;
