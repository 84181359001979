import React, { useState } from 'react'
import CheckTransfer2 from '../checkTransfer/checkTransfer2';
import CountdownTimer from '../components/CountDownTimer'
import HistoryContainer from '../history/HistoryContainer';

const Home = ({ contract }) => {
  const [group, setGroup] = useState("未");
  const [historyData, setHistoryData] = useState(null);

  if (contract === null) return;

  const getData = async () => {
    if (historyData !== null) return;
    let data = await contract.viewHistory();
    setHistoryData(data)
  }

  getData()

  if (historyData === null) return;

  //GET THE GROUP VALUE OF THE ACCOUNT

  /*
    Todo
    1. 判斷 中獎倒計時 內無新買單 =＞ 判斷該地址為中獎地址
    2. 判斷 該買單金額 >= 需求金額
    3. 先判斷中獎是否觸發
        3-1 若中獎觸發，一開始頁面便暫停
            等待項目方發送 中獎USDT後 再重新繼續
        3-2 若中獎未觸發，計算上筆買單時間 並進行倒計時
    4. 查看歷史中獎記錄：
        1.中獎地址
        2.中獎地址級別
        3.中獎數量
    5. 頁面預留
    
    醒目标注：活动页面有可能出现数据延迟，若有争议，以链上数据查询为准

    要求：
    1. 需要能自行設定
        1. 買單最低金額
        2. 買單間隔時間
        3. 修改歷史紀錄
    2. 
  */

  const identifyGroup = () => {
    //Todo
    //  1.查看地址身分
    //  2.確認是否加池
    //      2-1 : 未加池 15%
    //      2-2 : 已加池 未 20%
    //      2-3 : 已加池 人 30% 
    //      2-4 : 已加池 地 40%
    //      2-5 : 已加池 天 50%

  }

  const handleWinner = (value) => {
    console.log("Detecting last buy block in the countdown timer")
    console.log("The winner is " + value);
  }

  return (

    <div>
      <div className='home'>
        <div className='homeContent'>
          <div className='BigTitle'>
            <h5>活动页面有可能出现数据延迟，若有争议，以链上数据查询为准</h5>
          </div>
          <div>
            <h3>兔</h3>
          </div>
          <div className='countdown'>
            <CountdownTimer
              contract={contract}
              historyData={historyData} />
          </div>
          {/* <div className='homeKnowGroup'>
          <h3>
            身分組 : {group}
          </h3>
        </div> */}
        </div>
      </div>
    </div>

  )
}

export default Home
