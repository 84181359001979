import React from 'react'

function Products() {
  return (
    <div className='products'>
       <h3>Site Under Construction</h3>
    </div>
  )
}

export default Products
