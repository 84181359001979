import React, { useState, useEffect } from 'react';
import '../css/CountdownTimer.css';
import CheckTransfer from '../checkTransfer/checkTransfer';

function CountdownTimer({ contract, historyData }) {
    const [startBlock, setStartBlock] = useState(0);
    const [roundData, setRoundData] = useState(null);
    const [roundTime, setRoundTime] = useState(0);
    const [time, setTime] = useState(0);

    if(contract === null) return;
    if(historyData === null) return;


    const getRoundData = async () => {
        if (roundData !== null) return;
        let tempRoundData = await contract.roundID()
        setRoundData(tempRoundData);
    }
    const getStartBlock = async () => {
        if (historyData === null) return;
        if (historyData[roundData-1] === undefined) return;
        if (startBlock !== 0) return;
        let tempStartBlock = historyData[roundData-1][1];
        setStartBlock(tempStartBlock);
    }
    const getRoundTime = async () => {
        if (roundTime !== 0) return;
        let tempRoundTime = await contract.timeForRound()
        setRoundTime(tempRoundTime);
        setTime(tempRoundTime*3)
    }

    const getData = async () => {
        await getStartBlock()
        await getRoundData()
        await getRoundTime()
    }

    getData()

    // useEffect(() => {
    //     let interval;

    //     if (time > 0) {
    //         interval = setInterval(() => {
    //             setTime((prevTime) => prevTime - 3);
    //         }, 3000);
    //     }

    //     return () => clearInterval(interval);
    // }, [time]);

    // const hours = Math.floor(time / 3600);
    // const minutes = Math.floor((time % 3600) / 60);
    // const seconds = time % 60;

    // if (time === 0) {
    //     return (
    //         <div className="countdown-timer">
    //             <div className="time-display">Time's up!</div>
    //         </div>
    //     );
    // }

    if (roundTime === 0) return
    if (startBlock === 0) return
    if (roundData === null) return

    return (
        <div className="countdown-timer">
            {startBlock !== 0 && roundTime !==0 && roundData !== null &&
                <CheckTransfer
                    startBlock={startBlock}
                    roundTime={roundTime}
                />
            }
        </div>
    );
}

export default CountdownTimer;