import { ethers } from 'ethers';
import React, { useState } from 'react'
import tokenabi from '../abi/tokenabi.json'
import CheckTransfer2 from '../checkTransfer/checkTransfer2';

// const ControllerAddress = "0x135B2cB28B6C6ab1D71571bbe7b45A614A3662d1";

const ControllerAddress = "0x0D971B7B7520f1FCE9b90665CA59952ea2c52b04";
const ControllerAddress2 = "0x05d332A6f36c0512b1c241b319d856d2C9b8D7F3";

const tokenAddress = "0xD23178D86C0B66D6dd10DD05deB1776ADCCDa0Cc"
const zeroAddress = "0x0000000000000000000000000000000000000000"

const ControlPanel = ({ contract, account, signer }) => {
    const [timeInterval, setTimeInterval] = useState(0);
    const [isApprove, setIsApprove] = useState(false);
    const [winner, setWinner] = useState("No one yet");
    const [round, setRound] = useState(null);
    const [roundWinner, setRoundWinner] = useState(null);

    if (account !== ControllerAddress.toLowerCase() || account !== ControllerAddress2.toLowerCase() ) return;
    if (contract === null) return;

    const TokenContract = new ethers.Contract(tokenAddress, tokenabi, signer);

    const getRound = async () => {
        if (round !== null) return;
        let tempRound = await contract.roundID();
        setRound(tempRound);

        if (roundWinner !== null) return;
        let tempRoundWinner = await contract.roundWinner(tempRound - 1);
        setRoundWinner(tempRoundWinner);
    }

    getRound()

    if (round === null) return;

    const getTimeInterval = async () => {
        if (timeInterval !== 0) return;
        let tempTimeInterval = await contract.timeForRound();
        setTimeInterval(Number(tempTimeInterval))
    }

    const changeTime = async () => {
        const value = document.getElementById("amount").value;
        let result = await contract.setTimeForRound(value)
        reply(result)
    }

    const startNewRoundWithoutSending = async () => {
        let tempRound = await contract.roundID()
        if (Number(tempRound) === 0) {
            let result = await contract.initRound()
            reply(result)
        }
        else {
            let result = await contract.startNewRoundWithoutSendingUSDT(ControllerAddress)
            reply(result)
        }
    }


    const startNewRoundWithSending = async () => {
        if (roundWinner === zeroAddress) {
            return;
        }
        let tempRound = await contract.roundID()
        if (Number(tempRound) === 0) {
            let result = await contract.initRound()
            reply(result)
        }
        else {
            if (winner.length !== 42) {
                console.log(" Winner length = " + winner.length)
                return;
            }
            let reward = document.getElementById("USDTamount").value;
            let bigNum = ethers.utils.parseEther(reward);
            console.log(tempRound, winner, bigNum);
            try {
                contract.modifyRoundWinner(tempRound - 1, winner, bigNum)
                TokenContract.transfer(winner, bigNum);
                contract.startNewRoundWithoutSendingUSDT(ControllerAddress)
            } catch (err) {
                console.log(err)
            }
        }
    }

    const restartRound = async () => {
        let result = await contract.restartRound()
        reply(result);
    }

    const sendReward = async () => {
        try {
            if (roundWinner === zeroAddress) {
                alert("尚未设定本轮赢家")
                return;
            }
            let USDTamount = document.getElementById("USDTamount").value;
            let Winneraddress = document.getElementById("Winneraddress").value;

            if (Winneraddress.length !== 42) {
                alert("这不是地址，请检查是否有误")
                return;
            }

            let bigNum = ethers.utils.parseEther(USDTamount);
            console.log(bigNum);
            let sendUSDT = await TokenContract.transfer(Winneraddress, bigNum);
            if (sendUSDT) console.log(sendUSDT)
        } catch (err) {
            console.log(err)
        }
    }

    const changeHistory = async () => {
        let tempRound = document.getElementById("roundID").value;
        let tempAddress = document.getElementById("address").value;
        if (tempAddress.length !== 42) {
            alert("这不是地址，请检查是否有误")
            return;
        }
        let reward = document.getElementById("rewardAmount").value;
        let bigNum = ethers.utils.parseEther(reward);

        let result = await contract.modifyRoundWinner(tempRound, tempAddress, bigNum)
        reply(result)
    }

    const changeRule = async () => {
        let value = document.getElementById("rule").value;
        let result = await contract.changeString(value)
        reply(result)
    }

    const reply = (value) => {
        if (value) {
            console.log("Successfully Set")
        } else {
            console.log("error")
        }
    }


    /* 
        Call Functions Right Here
    */

    getTimeInterval();

    const handleWinner = (value) => {
        if (winner !== "No one yet") return;
        console.log("Detecting last buy block in the countdown timer")
        console.log("The winner is " + value);
        setWinner(value)
    }

    return (
        <div className='controlPanel'>
            <CheckTransfer2 contract={contract} winner={handleWinner} />
            <div className='panelCardRow1'>
                <div className='panelCard' >
                    <div className='panelRow'>
                        <div className='panelRowTitle'>
                            <br /> <br />
                            <h5>
                                时间间隔 : {timeInterval} 区块
                            </h5>
                        </div>
                    </div>
                    <div className='panelRow'>
                        <input autoComplete="off" type="text" id="amount" name="amount" placeholder="≧ 1" />
                        <input type="submit" value="更改一轮间隔" className='btnPrimary' onClick={changeTime} />
                        <br />
                    </div>
                </div>
                <div className='panelCard' >
                    <div className='panelRow'>
                        <input autoComplete="off" type="text" id="rule" name="rule" placeholder="请输入新规则" /><br />
                        <input type="submit" value="修改规则" className='btnPrimary' onClick={changeRule} />
                    </div>
                </div>
            </div>
            <div className='panelCardRow2'>
                <div className='panelCard' >
                    <div className='panelRow'>
                        <div className='panelRow'>
                            <div className='resultInControl'>
                                <h5>当前 第 {round - 1} 轮</h5>
                                <h5>
                                    本轮赢家 :  <br />
                                    {winner}
                                </h5>
                            </div>
                            <input autoComplete="off" type="text" id="roundID" name="roundID" placeholder="请输入第几轮" /><br />
                            <input autoComplete="off" type="text" id="address" name="address" placeholder="请输入地址" /><br />
                            <input autoComplete="off" type="text" id="rewardAmount" name="rewardAmount" placeholder="奖励数量" /><br />
                            <input type="submit" value="寫入贏家" className='btnPrimary' onClick={changeHistory} />
                        </div>
                    </div>
                </div>
                <div className='panelCard' >
                    <div className='panelRow'>
                        <div className='panelRow'>
                            <div className='resultInControl'>
                                <h5>
                                    本轮赢家 :  <br />
                                    {winner}
                                </h5>
                            </div>
                            <input autoComplete="off" type="text" id="Winneraddress" name="Winneraddress" placeholder="请输入地址" /><br />
                            <input autoComplete="off" type="text" id="USDTamount" name="USDTamount" placeholder="奖励数量" /><br />
                            <input type="submit" value="送出獎勵" className='btnPrimary' onClick={sendReward} />
                        </div>
                    </div>
                    <div className='panelRow'>
                        <button className='btnNewRound' onClick={startNewRoundWithSending}>
                            发送奖励 <br /> 并开启新的一轮<br />
                        </button>
                    </div>
                </div>
                <div className='panelCard' >

                    <div className='panelRow'>
                        <button className='btnNewRound' onClick={startNewRoundWithoutSending}>
                            开始新的一轮<br />
                        </button>
                    </div>

                    <div className='panelRow'>
                        <button className='btnNewRound' onClick={restartRound}>
                            本轮重新开始<br />
                        </button>
                    </div>
                </div>
                {/* <div className='panelCard' >
                    這個用來放
                </div>
                <div className='panelCard' >
                    這個用來放
                </div> */}
            </div>
        </div>
    )
}

export default ControlPanel
